/**
  * HACK: Override bootstrap's defaults, for the <NavLoginButton> implementation
  * inside the <Navbar> in example.js.
  * We don't want to use the rendered <NavDropDown> inside a <Nav> while in mobile mode
  * (otherwise, the menu items get displayed full screen width, which is undesirable).
  * In mobile mode, the CSS rules below let us fit the sign-in component to the top-right corner
  * and add back some of the default visuals provided by react-bootstrap's <Nav>.
  * In desktop and mobile modes, we change the link color to white for consistency with the AppMenu component.
  */

.otp.mobile .navbar .container-fluid > li {
  display: block;
  padding: 14px;
  position: fixed;
  right: 0;
  top: 0;
}
.otp.mobile .navbar li.dropdown.open {
  background-color: #080808;
}
.navbar-inverse .navbar-nav > li > a,
.otp.mobile .navbar .container-fluid > li > a,
.otp.mobile .navbar .container-fluid > li.dropdown.open > a {
  color: #fff;
  text-decoration: none;
}
.navbar-inverse .navbar-nav > li > a:hover,
.otp.mobile .navbar .container-fluid > li > a:hover {
  color: #ddd;
  text-decoration: none;
}
